<template>
    <div>
        <Processing v-if="isProcessing" />
        <div class="lesson-view" v-else>
            <div class="lesson-actions">
                <button class="btn btn-default btn--icon" @click="back">
                    <i class="zmdi zmdi-arrow-back"></i>
                </button>
                <button class="btn btn-default btn--icon" @click="edit">
                    <i class="zmdi zmdi-edit"></i>
                </button>
            </div>
            <dl>
                <dt>Date</dt>
                <dd>{{ lesson.date }}</dd>
            </dl>
            <dl>
                <dt>Title</dt>
                <dd>{{ lesson.title }}</dd>
            </dl>
            <dl>
                <dt>Reference</dt>
                <dd>{{ lesson.reference }}</dd>
            </dl>
            <dl>
                <dt>Content</dt>
                <dd>{{ lesson.content }}</dd>
            </dl>
            <dl>
                <dt>Action</dt>
                <dd>{{ lesson.action }}</dd>
            </dl>

            <dl v-if="lesson.isThought">
                <dt>Thoughts</dt>
                <dd>{{ lesson.thought }}</dd>
            </dl>
            <dl v-if="lesson.isQuestion">
                <dt>Question</dt>
                <dd>{{ lesson.question }}</dd>
            </dl>
            <dl v-if="lesson.isSoWhat">
                <dt>So What?</dt>
                <dd>{{ lesson.sowhat }}</dd>
            </dl>
            <dl>
                <dt>Recorded By</dt>
                <dd>{{ lesson.author.email }}</dd>
            </dl>
            <dl v-if="lesson.editedBy">
                <dt>Edited By</dt>
                <dd>{{ lesson.editedBy.email }}</dd>
            </dl>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            lesson: {},
            year: this.$store.state.devotionalYear,
            lessonId: this.$route.params.lessonId,
            month: this.$route.params.month,
            isProcessing: false,
        }
    },
    created(){
        const month = this.$route.fullPath.includes("/m/") ? this.$route.params.month : this.$moment().format("MMMM")
        const id = this.$route.params.lessonId
        this.viewLesson(month, id)
    },
    methods: {
        async viewLesson(month, id){
            this.isProcessing = true
            const snapshot = await this.$db
                .collection("devotionals")
                .doc("dailypower")
                .collection("year")
                .doc(this.$store.state.devotionalYear)
                .collection(month)
                .doc(id)
                .get()
            this.lesson = {
                id: snapshot.id,
                ...snapshot.data()
            }
            this.isProcessing = false
        },
        back(){
            this.$router.go(-1)
        },
        edit(){
            this.$router.push("/dailypower/m/" + this.month + "/" + this.lessonId + "/edit")
        },
    },
    components: {
        "Processing": () => import("@/components/Loaders/Processing.vue"),
    }
}
</script>
